import React, { Component } from "react";

export default class FaqSearchBox extends Component {
  render() {
    return (
      <div className="bg-gray-200">
        <div className="flex px-5 bg-white">
          <div class="relative m-auto w-full">
            <div className="absolute top-4 left-6">
              {" "}
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>{" "}
            </div>
            <input
              type="text"
              className="h-14 w-full pl-16 pr-5 z-0 focus:outline-none border-2 border-gray-500 rounded-full"
              placeholder="Search..."
            />
          </div>
        </div>
      </div>
    );
  }
}
